<template>
  <v-dialog :value="dialog" max-width="600">
    <perseu-card title="Comentários">
      <template #title-left>
        <back-button color="grey" @click="closeDialogOnBackButton" />
      </template>
      <template #content>
        <div class="mt-4">
          <v-textarea
            v-model="therapistComments"
            outlined
            label="Comentários exclusivo para terapeutas"
            rows="3"
            hint="Paciente não terá acesso"
            persistent-hint
            class="mb-5"
          />
        </div>
        <div>
          <v-textarea
            v-model="patientComments"
            outlined
            label="Comentários para o paciente"
            rows="3"
          />
        </div>
      </template>
      <template #actions>
        <v-btn large block color="secondary" @click="confirmComments">
          Confirmar
        </v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommentsDialog",
  data: () => ({
    therapistComments: "",
    patientComments: "",
  }),
  computed: {
    dialog() {
      return this.$store.state.settings.comments.dialog;
    },
  },
  methods: {
    confirmComments() {
      this.$store.dispatch("settings/confirmCommentsAndCloseDialog", {
        therapistComments: this.therapistComments,
        patientComments: this.patientComments,
      });
      this.clearComments();
    },
    closeDialogOnBackButton() {
      this.$store.dispatch("settings/closeCommentsDialog");
      this.clearComments();
    },
    clearComments() {
      this.therapistComments = "";
      this.patientComments = "";
    },
  },
};
</script>

<style scoped></style>
